require('jarallax/dist/jarallax.min');

var paymentInterval = false, searchAjax = undefined;

function getImageResolution(available) {
	var best = ['standard', undefined];
	if(window.matchMedia) {
		best[1] = window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)').matches;
		if(_.isEmpty(available))
			return ['standard', best[1]];
		if(available.indexOf('xsmall') >= 0 && window.matchMedia('screen and (max-width: 575px)').matches)
			return ['xsmall', best[1]];
		if(available.indexOf('small') >= 0 && window.matchMedia('screen and (max-width: 767px)').matches)
			return ['small', best[1]];
		if(available.indexOf('medium') >= 0 && window.matchMedia('screen and (max-width: 991px)').matches)
			return ['medium', best[1]];
		if(available.indexOf('large') >= 0 && window.matchMedia('screen and (max-width: 1199px)').matches)
			return ['large', best[1]];
		if(available.indexOf('xlarge') >= 0)
			return ['xlarge', best[1]];
	} else {
		if(_.isEmpty(available))
			return ['standard', undefined];
		if(available.indexOf('xsmall') >= 0 && window.outerWidth < 575)
			return ['xsmall', undefined];
		if(available.indexOf('small') >= 0 && window.outerWidth < 767)
			return ['small', undefined];
		if(available.indexOf('medium') >= 0 && window.outerWidth < 991)
			return ['medium', undefined];
		if(available.indexOf('large') >= 0 && window.outerWidth < 1199)
			return ['large', undefined];
		if(available.indexOf('xlarge') >= 0)
			return ['xlarge', undefined];
	}
	return best;
}

function applyImageResolution(elem, image, background) {
	var size = getImageResolution(elem.data('sizes').split(' ')),
		replace = undefined;
	if(size[0] != 'standard')
		replace = '.'+size[0];
	if(size[1])
		replace = (_.isUndefined(replace) ? '' : replace) + '@2x';
	if(background) {
		if(!_.isUndefined(replace))
			elem.css('background-image', 'url("'+image.replace(/\.([a-z]+)$/, replace+'.$1')+'")');
		else
			elem.css('background-image', 'url("'+image+'")');
		elem.removeAttr('data-background');
	} else {
		if(!_.isUndefined(replace))
			elem.attr('src', image.replace(/\.([a-z]+)$/, replace+'.$1'));
		else
			elem.attr('src', image);
		if(_.isUndefined(size[1]))
			elem.attr('srcset', image.replace(/\.([a-z]+)$/, (_.isUndefined(replace) ? '' : replace)+'@2x.$1'));
		elem.removeAttr('data-image');
	}
	elem.removeAttr('data-sizes');
}

function parseImages(holder) {
	holder.find('[data-background], [data-image]').each(function() {
		var t = $(this);
		if(_.isUndefined(t.data('image')))
			applyImageResolution(t, t.data('background'), true);
		else
			applyImageResolution(t, t.data('image'), false);
	});
	return holder;
}

function monitorPayment() {
	$.ajax({
		url: '/helpers/status.php',
		success: function(text) {
			if(paymentInterval) {
				if(text == 'CREATED' || text == 'ITEMS_IN_BASKET')
					window.location = '#checkout/details';
				else if(text != 'AWAITING_PAYMENT')
					window.location = '#checkout/summary';
				else
					window.setTimeout(monitorPayment, 1000);
			}
		},
		error: function() {
			window.setTimeout(monitorPayment, 1000);
		}
	});
}

function formatAddressForm(syntax, customer, labels) {
	var html = '';
	_.each(syntax.split('\n'), function(line) {
		html += '<div class="field-row">';
		_.each(line.split(' '), function(field) {
			html += '<div><input type="text" name="'+field+'" placeholder="'+labels[field]+'"';
			if(!_.isEmpty(customer.address[field]))
				html += ' value="'+_.escape(customer.address[field])+'"';
			html += ' /></div>';
		});
		html += '</div>';
	});
	return html;
}

function buildNavBasket(data) {
	return '<a href="#basket" class="icon-basket"><span>'+_.size(data.basket)+'</span></a><span class="nav-header">'+data.labels.basket+'</span><a href="#close" class="icon-close"></a><ul>'+buildBasket(data)+'<li><p>'+data.labels.total+': <span class="price">'+data.total+'</span></p><a href="#checkout" class="btn btn-success">Checkout</a></li></ul>';
}

function buildBasket(data) {
	var html = '';
	_.each(data.basket, function(product) {
		html += '<li><form method="POST"><button type="submit" class="img">'+product.icon+'<span><span class="fas fa-times"></span>'+data.labels.remove+'</span></button><input type="hidden" name="order_remove" value="'+product.fpc+'" /></form><p>'+product.name+'</p><aside>'+product.info+'</aside></li>'
	});
	return html;
}

function buildBasketSection(data) {
	var html = '', hasAside = !_.isEmpty(data.swap) || !_.isEmpty(data.dlcs) || !_.isEmpty(data.bases);
	html += '<div class="col-md-'+(hasAside ? 5 : 12)+'"><h6>'+data.labels.basket+'</h6><ul class="basket">'+buildBasket(data)+'</ul><div class="basket-total"><small>'+data.labels.total+':</small> '+data.total+'</div></div>';
	if(hasAside) {
		html += '<div class="col-md-7">';
		if(!_.isEmpty(data.bases)) {
			html += '<h6>'+data.labels.base+'</h6><ul class="bases">';
			_.each(data.bases, function(product) {
				html += '<li><div>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></div><form method="POST"><input type="hidden" name="order_buy" value="'+product.fpc+'" /><button type="submit" class="btn btn-info">'+data.labels.add+'</button></form></li>';
			});
			html += '</ul>';
		}
		if(!_.isEmpty(data.swap)) {
			html += '<h6>'+data.labels.upgrade+'</h6><ul class="upgrades">';
			_.each(data.swap, function(product) {
				html += '<li><div>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></div><form method="POST"><input type="hidden" name="order_swap" value="'+product.fpc+'" /><button type="submit" class="btn btn-info">'+data.labels.swap+'</button></form></li>';
			});
			html += '</ul>';
		}
		if(!_.isEmpty(data.dlcs)) {
			html += '<h6>'+data.labels.dlc+'</h6><ul class="addons">';
			_.each(data.dlcs, function(game) {
				html += '<li><p>'+game.name+'</p><ul>';
				_.each(game.products, function(product) {
					html += '<li><div>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></div><form method="POST"><input type="hidden" name="order_buy" value="'+product.fpc+'" /><button type="submit" class="btn btn-info">'+data.labels.add+'</button></form></li>';
				});
				html += '</ul></li>';
			});
			html += '</ul>';
		}
		html += '</div>';
	}
	return html;
}

function buildWholeCheckoutSection(section, data, modal) {
	var html = '';
	if(section == 'details') {
		html += '<form method="POST" action="#checkout/payment" class="modal-content"';
	} else {
		html += '<div class="modal-content"';
	}
	if(modal.hasClass('show'))
		html += ' style="display: none"';
	html += ' data-current="'+section+'"><div class="modal-header"><div class="modal-title" id="checkoutModalLabel"><h5>'+data.labels.header+'</h5></div></div>';
	if(!_.isEmpty(data.error)) {
		html += '<div class="modal-error">'+data.error+'</div>';
		history.replaceState(null, '', '#checkout/'+section);
	}
	html += '<div class="modal-body"><div class="row">';
	if(section == 'basket') {
		html += buildBasketSection(data);
	} else if(section == 'details') {
		html += '<input type="hidden" name="payment_method" value="STANDARD" /><input type="hidden" name="order_details" value="1" /><div class="col-md-5"><h6>'+data.labels.basket+'</h6><ul>';
		_.each(data.basket, function(product) {
			html += '<li';
			if(!_.isEmpty(product.restrictions))
				html += ' data-restricted="'+product.restrictions.join(',')+'"';
			html += '>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></li>';
		});
		html += '</ul><div class="basket-total"><small>'+data.labels.total+':</small> '+data.total+'</div></div><div class="col-md-7"><h6>'+data.labels.details+'</h6><fieldset id="checkoutDetails"><div class="form-group"><label for="checkoutModalName">'+data.labels.name_label+':</label><div class="field-row"><input type="text" name="name" id="checkoutModalName" placeholder="'+data.labels.name_placeholder+'"';
		if(!_.isEmpty(data.customer.name))
			html += ' value="'+_.escape(data.customer.name)+'"';
		html += ' /></div></div><div class="form-group"><label for="checkoutModalEmail">'+data.labels.email_label+':</label><div class="field-row"><input type="email" name="email" id="checkoutModalEmail"';
		if(!_.isEmpty(data.customer.email))
			html += ' value="'+_.escape(data.customer.email)+'"';
		html += ' /></div></div><div class="form-group"><label for="checkoutModalCountry">'+data.labels.country_label+':</label><div id="countryFlex"><input type="text" readonly id="checkoutModalCountry" value="'+data.country+'" /><button class="btn btn-info">'+data.labels.change_country+'</button></div></div><div class="form-group"><label for="checkoutModalStreet">'+data.labels.billing_label+':</label><div class="details">'+formatAddressForm(data.country_syntax, data.customer, data.labels)+'</div></div></fieldset></div>';
	} else if(section == 'payment') {
		if(data.iframeable) {
			html += '<div class="col-md-5"><h6>'+data.labels.basket+'</h6><ul>';
			_.each(data.basket, function(product) {
				html += '<li>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></li>';
			});
			html += '</ul><div class="basket-total"><small>'+data.labels.total+':</small> '+data.total+'</div></div><div class="col-md-7"><h6>'+data.labels.details+'</h6><table><tbody><tr><th>'+data.labels.name_label+':</th><td>'+_.escape(data.customer.name)+'</td></tr><tr><th>'+data.labels.email_label+':</th><td>'+_.escape(data.customer.email)+'</td></tr><tr><th>'+data.labels.country_label+':</th><td>'+_.escape(data.customer.country)+'</td></tr><tr><th>'+data.labels.billing_label+':</th><td>'+_.escape(data.customer.address)+'</td></tr></tbody></table><h6>'+data.labels.payment+'</h6><iframe src="'+data.url+'" id="paymentframe"></iframe></div>';
		} else
			window.location = data.url;
	} else if(section == 'summary') {
		html += '<div class="col-md-5"><h6>'+data.labels.basket+'</h6><ul>';
		_.each(data.basket, function(product) {
			html += '<li';
			if(!_.isEmpty(product.restrictions))
				html += ' data-restricted="'+product.restrictions.join(',')+'"';
			html += '>'+product.icon+'<p>'+product.name+'</p><aside>'+product.info+'</aside></li>';
		});
		html += '</ul><div class="basket-total"><small>'+data.labels.total+':</small> '+data.total;
		if(!_.isUndefined(data.fee))
			html += '<small>'+data.fee+'</small>';
		html += '</div></div><div class="col-md-7 purchase_summary">'+data.labels.summary+'</div>';
		$('#primary-nav-basket').remove();
	}
	html += '</div></div><div class="modal-footer"><div class="row"><div class="col-'+(_.isEmpty(data.success) ? 12 : 5)+'">'+data.return+'</div>';
	if(!_.isEmpty(data.success))
		html += '<div class="col-7">'+data.success.join('')+'</div>';
	if(!_.isEmpty(data.labels.fee))
		html += '<small>'+data.labels.fee+'</small>';
	html += '</div></div>';
	if(section == 'details') {
		html += '</form>';
	} else {
		html += '</div>';
	}
	if(modal.hasClass('show')) {
		var action = 'after';
		switch(modal.find('.modal-content').data('current')) {
			case 'details':
				if(section == 'basket')
					action = 'before';
				break;
			case 'payment':
				if(section == 'basket' || section == 'details')
					action = 'before';
				break;
			case 'summary':
				action = 'before';
				break;
		}
		modal.find('.modal-content')[action](html).slideUp(function() {
			$(this).remove();
		})[action == 'after' ? 'next' : 'prev']().slideDown();
	} else {
		modal.find('.modal-content').replaceWith(html);
		modal.modal('show');
	}
	if(section == 'payment') {
		paymentInterval = true;
		monitorPayment();
	}
}

function loadAndOpenCheckout() {
	var modal = $('#checkoutModal');
	$.ajax({
		url: '/helpers/checkout.php',
		data: {
			href: window.location.href
		},
		success: function(data, status, xhr) {
			if(xhr.getResponseHeader('X-Checkout-Redirect') != null)
				window.location = xhr.getResponseHeader('X-Checkout-Redirect');
			else {
				var section = xhr.getResponseHeader('X-Checkout-Section');
				buildWholeCheckoutSection(section, data, modal);
			}
		},
		error: function(xhr) {
			history.replaceState(null, '', '#'); // better to not to do anything, just force user to retry
		}
	});
}

function loadAndOpenContact(url) {
	var modal = $('#contactModal');
	if(_.isUndefined(url))
		url = window.location.href;
	$.ajax({
		url: '/helpers/contact.php',
		data: {
			href: url
		},
		success: function(html, status, xhr) {
			if(xhr.getResponseHeader('X-Checkout-Redirect') != null)
				window.location = xhr.getResponseHeader('X-Checkout-Redirect');
			else {
				parseImages(modal.find('.modal-content').html(html).toggleConfirmation(false));
				if(!modal.hasClass('show'))
					modal.modal('show');
			}
		},
		error: function(xhr) {
			history.replaceState(null, '', '#'); // better to not to do anything, just force user to retry
		}
	});
}

function loadAndOpenGame() {
	var modal = $('#buyModal');
	$.ajax({
		url: '/helpers/buy.php',
		data: {
			href: window.location.href,
			mobile: isMobile()
		},
		success: function(html, status, xhr) {
			parseImages(modal.find('.modal-dialog').html(html));
			if(!modal.hasClass('show'))
				modal.modal('show');
		},
		error: function(xhr) {
			var redirect = xhr.getResponseHeader('X-Redirect-To');
			if(!_.isEmpty(redirect))
				window.location = redirect;
			else {
				window.location = '#';
				if(modal.hasClass('show'))
					modal.modal('hide');
			}
		}
	});
}

function loadAndOpenFaq() {
	var modal = $('#faqModal');
	$.ajax({
		url: '/helpers/faq.php',
		data: {
			href: window.location.href,
			mobile: isMobile()
		},
		dataType: 'json',
		success: function(data, status, xhr) {
			if(xhr.getResponseHeader('X-Checkout-Redirect') != null)
				window.location = xhr.getResponseHeader('X-Checkout-Redirect');
			else {
				modal.find('#faqModalLabel').html(data.name);
				parseImages(modal.find('.modal-body').attr('id', 'faq-'+data.id).html(data.content));
				if(!modal.hasClass('show'))
					modal.modal('show');
			}
		},
		error: function(xhr) {
			history.replaceState(null, '', '#'); // better to not to do anything, just force user to retry
		}
	});
}

function loadAndOpenLibrary() {
	var modal = $('#libraryModal');
	$.ajax({
		url: window.location.href.replace(/\/#(.+)$/, '/$1.txt'),
		success: function(data, status, xhr) {
			modal.find('#libraryModalLabel').html(xhr.getResponseHeader('X-File-Name'));
			modal.find('.modal-body').html(data);
			if(!modal.hasClass('show'))
				modal.modal('show');
		},
		error: function(xhr) {
			history.replaceState(null, '', '#'); // better to not to do anything, just force user to retry
		}
	});
}

function isMobile() {
	return getImageResolution(['small'])[0] == 'small';
}

function handleGlobalHashChange() {
	var hash = window.location.hash.substr(1);
	if(hash.indexOf('checkout') == 0)
		loadAndOpenCheckout();
	else if(hash.indexOf('buy/') == 0)
		loadAndOpenGame();
	else if(hash.indexOf('contact/') == 0)
		loadAndOpenContact();
	else if(hash.indexOf('faqs/') == 0 && window.location.pathname.match(/^\/[^\/]+\/support\//))
		loadAndOpenFaq();
	else if(window.location.pathname.match(/^\/[^\/]+\/legal\//))
		loadAndOpenLibrary();
	else if(hash.length == 0)
		$('.modal.show').modal('hide');
}

jQuery.fn.extend({
	toggleConfirmation: function(on) {
		var data = on ? 'confirm' : 'create';
		this[on ? 'addClass' : 'removeClass']('confirming').find('.modal-footer .btn').each(function() {
			var t = $(this);
			t.html(t.data(data));
		});
		return this;
	}
})

$(window).on('hashchange', function(e) {
	e.preventDefault();
	handleGlobalHashChange();
}).on('hashto', function(e, href) {
	history.pushState(null, '', href);
	handleGlobalHashChange();
});

function prevUntil(object, selector) {
	object = object.prev();
	if(object.is(selector))
		return object;
	return prevUntil(object, selector);
}

function getLevelPosition(level) {
	var outerWidth = $('#radar-render-'+(level*2)).width();
	return (outerWidth-(outerWidth-$('#radar-render-'+(level*2+1)).width())/2)/2;
}

function calculateRadarSizes() {
	return {
		center: $('#radar-render').width()/2-$('#radar-render .radar-render-blip-circle').outerWidth(true)/2,
		notSoon: $('#radar-render-2').width()/2,
		quiteSoon: getLevelPosition(2),
		soon: getLevelPosition(3),
		verySoon: getLevelPosition(4),
		window: $(window).width()
	};
}

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
	return array;
}

function htmlGrowl(message) {
	$('#growlNotifications').append('<li>'+message+'</li>');
}

function growl(message) {
	$('#growlNotifications').append('<li>'+_.escape(message)+'</li>');
}

window.growl = growl;

function startAndRotateRadar() {
	var sizes = calculateRadarSizes(), blips = [];
	$(window).on('resize', function() {
		sizes = calculateRadarSizes();
	});
	
	var allBlips = $('#radar-render .radar-render-blip'),
		perBlip = 360 / allBlips.length,
		shuffle = shuffleArray(_.range(allBlips.length)),
		speeds = shuffleArray(_.range(2, allBlips.length*2+2, 2));
	allBlips.each(function(i) {
		var t = $(this), min = Math.ceil(perBlip*shuffle[i])+10, max = Math.floor(perBlip*(shuffle[i]+1))-10;
		blips.push({
			elem: t,
			angle: (Math.floor(Math.random() * (max - min)) + min) * (Math.PI / 180),
			level: t.attr('class').replace(/.*radar-render-blip-([a-zA-Z]+).*/, '$1'),
			speed: speeds[i]
		});
	});
	var move = 0;
	window.setInterval(function() {
		_.each(blips, function(blip) {
			var left = sizes[blip.level]*Math.cos(blip.angle+(move/blip.speed))+sizes.center;
			blip.elem.css({
				left: left,
				top: sizes[blip.level]*Math.sin(blip.angle+(move/blip.speed))+sizes.center
			})[left + 300 > sizes.window ? 'addClass' : 'removeClass']('left');
		});
		move = (move + .1) % 360;
	}, 200);
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function renderPagination(query, tab, current, total) {
	var html = '', i = 0, last = Math.ceil(total/10);
	if(total > 10) {
		if(current > 1)
			html += '<a href="?'+$.param({
				query: query,
				tab: tab,
				page: current-1
			})+'"><span class="fa fa-chevron-left"></span></a>';
		var start = Math.max(current-2, 1), end = Math.min(current+2, last);
		if(start > 1) {
			html += '<a href="?'+$.param({
				query: query,
				tab: tab,
				page: 1
			})+'">1</a>';
			if(start > 2)
				html += '<span class="ellipsis">...</span>';
		}
		for(i = start; i <= end; i++) {
			html += '<a href="?'+$.param({
				query: query,
				tab: tab,
				page: i
			})+'"';
			if(i == current)
				html += ' class="active"';
			html += '>'+i+'</a>';
		}
		if(end < last) {
			if(end < last - 1)
				html += '<span class="ellipsis">...</span>';
			html += '<a href="?'+$.param({
				query: query,
				tab: tab,
				page: last
			})+'">'+last+'</a>';
		}
		if(current < last)
			html += '<a href="?'+$.param({
				query: query,
				tab: tab,
				page: current+1
			})+'"><span class="fa fa-chevron-right"></span></a>';
	}
	return html;
}

window.minisiteIFrameLoaded = function(href) {
	window.history.pushState(null, '', href);
}

window.markVolumeMuted = function() {
	$('#primary-nav-controls .fa-volume-up').removeClass('fa-volume-up').addClass('fa-volume-mute');
}

$(document).ready(function() {
	var body = $('body');
	parseImages($('html'));
	$('.jarallax').jarallax();
	if(isMobile()) {
		$('[data-divider]').each(function() {
			var t = $(this);
			t.css('border-top', 'solid 10px '+t.data('divider'));
		});
		if(body.hasClass('search-page')) {
			$('#primary-nav').addClass('nav-expanded').addClass('nav-expand-search');
			$('#search-form').hide();
		}
	} else
		$('[data-divider]').each(function() {
			var t = $(this);
			prevUntil(t.css('position', 'relative').addClass('svg-divided').append('<span class="divider-svg" style="background-image: url(/images/dividers/'+t.data('divider').substr(1)+'.svg'+(t.data('divider-reverse') ? '?reverse=true' : '')+')"></span>'), ':not(.modal)').addClass('svg-divider-after');
		});
	if(window.location.hash.length > 0)
		$(window).trigger('hashchange');
	// if(body.hasClass('radar-page'))
		// startAndRotateRadar();
}).on('click', 'a.copy-link', function(e) {
	e.preventDefault();
	var button = $(this), text = button.attr('href'), deferred = $.Deferred();
	
	if(navigator.clipboard) {
		navigator.clipboard.writeText(text).then(function() {
			deferred.resolve();
		}, function(err) {
			deferred.reject();
		});
	} else {
		var range = document.createRange(),
			selection = window.getSelection(),
			copySpan = $('#copy_selection span');
			
		if(copySpan.length == 0)
			$('body').append('<div id="copy_selection"><span></span></div>');
			
		selection.removeAllRanges();
		range.selectNode($('#copy_selection span').html(_.escape(text)).get(0));
		selection.addRange(range);

		if(document.queryCommandEnabled('copy') && document.queryCommandSupported('copy')) {
			document.execCommand('copy');
			deferred.resolve();
		} else
			deferred.reject();
	}
	
	deferred.promise().done(function() {
		if(button) {
			var initialClass = button.find('span').attr('class');
	
			button.find('span').attr('class', 'fa fa-clipboard-check');
			window.setTimeout(function() {
				button.find('span').attr('class', initialClass);
			}, 2000);
		}
	}).fail(function() {
		prompt('On-click copy not supported. Copy selected text manually.', text);
	});
	
}).on('click', '#growlNotifications > li', function(e) {
	$(this).fadeOut(function() {
		$(this).remove();
	});
}).on('change', '#changePageLanguage, #changePageLanguageMobile', function() {
	window.location = window.location.href.replace(/(http[s]*:\/\/[^\/]+\/)[^\/]+/, '$1'+$(this).val());
}).on('change', '#changePageCountry, #changePageCountryMobile', function() {
	$('<form method="POST"><input type="hidden" name="country" value="'+$(this).val()+'" /></form>').appendTo($('body')).submit();
}).on('click', '#primary-nav-controls > a.icon-menu', function(e) {
	e.preventDefault();
	$(this).toggleClass('icon-close').toggleClass('icon-menu');
	$(this).closest('#primary-nav').toggleClass('open-menu');
	$('#primary-nav-pages ul').slideToggle();
}).on('click', '#primary-nav-controls > a.icon-close', function(e) {
	e.preventDefault();
	$(this).toggleClass('icon-menu').toggleClass('icon-close');
	$(this).closest('#primary-nav').toggleClass('open-menu');
	$('#primary-nav-pages ul').slideToggle();
}).on('click', '#primary-nav-controls > a.fa-volume-up, #primary-nav-controls > a.fa-volume-mute', function(e) {
	e.preventDefault();
	var t = $(this), minisite = $('#minisite-content > iframe'), muted = false;
	
	if(minisite.length == 1)
		muted = minisite.contents().find('#background_audio').length != 0 ?
			minisite.contents().find('#background_audio')[0].muted :
			minisite.contents().find("audio:first")[0].muted;
	else
		muted = $('#background_audio').length != 0 ?
			$('#background_audio')[0].muted :
			$("audio:first")[0].muted;
	
	if(t.hasClass('fa-volume-up')) { // mute
		t.removeClass('fa-volume-up').addClass('fa-volume-mute');
		if(!muted) {
			if(minisite.length == 1)
				minisite.get(0).contentWindow.setMuted(true);
		}
	} else { // unmute
		t.removeClass('fa-volume-mute').addClass('fa-volume-up');
		if(muted) {
			if(minisite.length == 1)
				minisite.get(0).contentWindow.setMuted(false);
		}
	}
}).on('click', '#primary-nav-controls .nav-expand > a', function(e) {
	e.preventDefault();
	$('h1').addClass('shrink');
	var t = $(this);
	t.closest('#primary-nav').removeAttr('class').addClass('nav-expanded').addClass('nav-expand-'+t.attr('href').substr(1));
	t.closest('.nav-expand').find('input[type=search]').focus();
	/*
	$('#primary-nav-pages').animate({ width: 0, 'flex-grow': 0, 'flex-shrink': 0 }, 350);
	t.closest('.nav-expand').css({ flex: '1 1 auto' });
	$('#primary-nav-controls').animate({ width: '100%' }, 350);
	$('#primary-nav-basket').animate({ width: 0, margin: 0, padding: 0 }, 350);
	*/
}).on('click', '.nav-expanded .nav-expand > a.icon-close', function(e) {
	e.preventDefault();
	$('h1').removeClass('shrink');
	var t = $(this);
	t.closest('.nav-expanded').removeAttr('class');
	t.closest('.nav-expand').find('> form > input[type=search]').val('').trigger('input');
}).on('input', '#primary-nav-search input', function(e) {
	var t = $(this);
	if(!_.isUndefined(searchAjax))
		searchAjax.abort();
	searchAjax = $.ajax({
		url: '/helpers/search.php',
		dataType: 'html',
		data: {
			query: t.val(),
			href: $(this).closest('form').attr('action')
		},
		success: function(html) {
			parseImages($('#primary-nav-search ul').html(html));
		} // nothing happens on error - just don't update the list at all
	});
}).on('click', '#basic-site-map-toggle', function(e) {
	e.preventDefault();
	$(this).closest('footer').find('> ul, > p, > a').slideToggle();
}).on('show.bs.modal', '#contactModal', function() {
	$(this).find('form').toggleConfirmation(false);
}).on('shown.bs.modal', '#buyModal, #checkoutModal, #contactModal, #faqModal, #libraryModal', function() {
	$('.nav-expanded .nav-expand > a.icon-close').trigger('click');
}).on('hide.bs.modal', '#buyModal, #checkoutModal, #contactModal, #faqModal, #libraryModal', function() {
	if(!_.isEmpty(window.location.hash))
		window.history.replaceState(null, '', window.location.href.replace(window.location.hash, ''));
	// window.history.back();
	paymentInterval = false;
}).on('click', '.modal-body a.handle', function(e) {
	e.preventDefault();
	$(this).toggleClass('open').next().slideToggle();
}).on('click', '.modal-body .requirements > nav a:not(.active), .modal-body .reqs_selector a:not(.active)', function(e) {
	e.preventDefault();
	var t = $(this);
	t.closest('nav').find('a').removeClass('active');
	t.addClass('active');
	t.closest('nav').removeClass('ios_reqs');
	t.closest('nav').removeClass('android_reqs');
	t.closest('nav').addClass(''+t.attr('href').substr(1));
	t.closest('.requirements').find('.reqs_holder').slideUp().filter(t.attr('href')).slideDown();
}).on('input', '#game-filters input[type=search]', function(e) {
	var filter = $(this).val().toLowerCase();
	$('#games-list .panel').each(function() {
		var t = $(this);
		t.parent()[filter.length == 0 || t.find('h4').text().toLowerCase().indexOf(filter) >= 0 ? 'show' : 'hide']();
	});
}).on('input', '#faqs-filters input[type=search]', function(e) {
	var filter = $(this).val().toLowerCase();
	$('#faqs-games nav > a').each(function() {
		var t = $(this);
		t[filter.length == 0 || t.find('span').text().toLowerCase().indexOf(filter) >= 0 ? 'show' : 'hide']();
	});
}).on('click', '.news-page #load_more a', function(e) {
	e.preventDefault();
	var t = $(this), div = t.closest('div'), span = t.find('span').addClass('fa-spin');
	$.ajax({
		url: t.attr('href').replace('contents_after=x', 'contents_after='+div.prev().attr('id').substr(1)),
		success: function(data, status, xhr) {
			var holder = div.before(data).parent();
			parseImages(holder);
			holder.find('.jarallax').jarallax();
			if(xhr.getResponseHeader('x-has-more-articles') == 'false')
				t.closest('#load_more').remove();
			else
				span.removeClass('fa-spin');
		} // nothing happens on error - just don't update the list at all
	});
}).on('change', '.news-page #news-filters select, .support-page #faqs-filters select, .careers-page #careers-filter select', function() {
	window.location = $(this).val();
}).on('click', '#search-results.loading-results a', function(e) {
	e.preventDefault();
	return false;
}).on('click', '.search-page #search-tabs-selector a', function(e) {
	e.preventDefault();
	var t = $(this), resultsHolder = $('#search-results');
	if(!t.hasClass('active') && !resultsHolder.hasClass('loading-results')) {
		resultsHolder.addClass('loading-results');
		t.addClass('loading-results');
		$.ajax({
			method: 'GET',
			url: '/helpers/search.php'+t.attr('href'),
			data: {
				href: window.location.href
			},
			success: function(data, result, xhr) {
				t.closest('nav').find('a.active').removeClass('active');
				t.addClass('active');
				$('#search-results-tabs ul.active').removeClass('active');
				parseImages($('#search-results-tabs ul.tab-'+t.data('tab')).html(data).addClass('active'));
				$('#search-pagination').html(renderPagination(new URL('http://feral.com'+t.attr('href')).searchParams.get('query'), t.data('tab'), 1, parseInt(xhr.getResponseHeader('X-Results-Count'))));
				window.history.pushState(null, '', t.attr('href'));
				resultsHolder.removeClass('loading-results');
				t.removeClass('loading-results');
			},
			error: function(xhr) {
				growl(xhr.responseText);
				resultsHolder.removeClass('loading-results');
				t.removeClass('loading-results');
			}
		});
	}
}).on('click', '.search-page #search-pagination a', function(e) {
	e.preventDefault();
	var t = $(this), resultsHolder = $('#search-results');
	if(!t.hasClass('active') && !resultsHolder.hasClass('loading-results')) {
		resultsHolder.addClass('loading-results');
		t.addClass('loading-results');
		$.ajax({
			method: 'GET',
			url: '/helpers/search.php'+t.attr('href'),
			data: {
				href: window.location.href
			},
			success: function(data, result, xhr) {
				$('#search-results-tabs ul.active').html(data);
				var params = new URL('http://feral.com'+t.attr('href')).searchParams;
				// t.closest('nav').find('a.active').removeClass('active');
				// t.addClass('active');
				$('#search-pagination').html(renderPagination(params.get('query'), params.get('tab'), parseInt(params.get('page')), parseInt(xhr.getResponseHeader('X-Results-Count'))));
				window.history.pushState(null, '', t.attr('href'));
				resultsHolder.removeClass('loading-results');
				t.removeClass('loading-results');
			},
			error: function(xhr) {
				growl(xhr.responseText);
				resultsHolder.removeClass('loading-results');
				t.removeClass('loading-results');
			}
		});
	}
}).on('click', '.radar-page #radar-render a.radar-render-blip-circle', function(e) {
	e.preventDefault();
	if(isMobile()) {
		var t = $(this);
		$('#radar-render').append('<div id="radar-popup" class="radar-popup-'+t.closest('.radar-render-blip').attr('class').replace(/.*radar-render-blip-([a-zA-Z]+).*/, '$1')+'">'+t.next().html()+'<a href="#"><span class="icon-close"></span> Close</a></div>')
	} else {
		$(this).closest('.radar-render-blip').addClass('open');
	}
}).on('click', '.radar-page #radar-popup > a', function(e) {
	e.preventDefault();
	var popup = $(this).closest('#radar-popup');
	popup.fadeOut(function() {
		popup.remove();
	});
}).on('click', '.radar-page #radar-center > .game-image', function(e) {
	e.preventDefault();
	$(this).parent().addClass('open');
}).on('click', '.radar-page #radar-center-holder > a', function(e) {
	e.preventDefault();
	e.stopPropagation();
	$(this).closest('#radar-center').removeClass('open');
}).on('click', '.radar-page #radar-holder:has(.radar-render-blip.open)', function(e) {
	e.preventDefault();
	e.stopPropagation();
	$(this).find('.radar-render-blip.open').removeClass('open');
}).on('click', 'nav.tabbed a:not(.active)', function(e) {
	e.preventDefault();
	var t = $(this), nav = t.closest('nav'), tabs = $(nav.data('for'));
	nav.find('a.active').removeClass('active');
	t.addClass('active');
	tabs.find(' > .active').removeClass('active');
	tabs.find(t.attr('href')).addClass('active');
}).on('click', 'a.page-slide', function(e) {
	e.preventDefault();
    $('body').animate({
		scrollTop: $($(this).attr('href')).offset().top
	}, 500);
}).on('click', '.accounts-page button[data-order][data-target]', function(e) {
	e.preventDefault();
	var t = $(this),
		target = $(t.data('target')),
		cmp, alphabetical = function(a, b) {
			if(a.find('strong.orderlast').length > 0)
				return 1;
			if(b.find('strong.orderlast').length > 0)
				return -1;
			return a.find('strong').text().localeCompare(b.find('strong').text());
		};
	if(t.data('order') == 'progress')
		cmp = function(a, b) {
			var at = $(a), as = at.find('> small'), bt = $(b), bs = bt.find('> small');
			if(as.length > 0) {
				var ap = as.text().match(/([0-9]+) \/ ([0-9]+)$/);
				if(parseFloat(ap[1]) > 0) {
					if(bs.length > 0) {
						var bp = bs.text().match(/([0-9]+) \/ ([0-9]+)$/);
						if(parseFloat(bp[1]) > 0) {
							ap = parseFloat(ap[1])/parseFloat(ap[2]);
							bp = parseFloat(bp[1])/parseFloat(bp[2]);
							if(ap == bp)
								return alphabetical(at, bt);
							return bp-ap;
						}
					}
					return -1;
				}
			}
			if(bs.length > 0) {
				var bp = bs.text().match(/([0-9]+) \/ ([0-9]+)$/);
				if(parseFloat(bp[1]) > 0)
					return 1;
			}
			return alphabetical(at, bt);
		}
	else if(t.data('order') == 'date')
		cmp = function(a, b) {
			return $(b).find('> small').text().localeCompare($(a).find('> small').text());
		}
	else
		cmp = function(a, b) {
			return alphabetical($(a), $(b));
		};
	t.parent().find('button[data-order][data-target]').removeClass('active');
	t.addClass('active');
	target.find('li').sort(cmp).appendTo(target);
}).on('change', '#account-avatar input[name$=_avatar]', function(e) {
	var t = $(this);
	t.closest('label').addClass('checked');
	t.closest('form').find('input[type=radio]').each(function(e) {
		var ti = $(this);
		if(!ti.is(t))
			ti.prop('checked', false).closest('label').removeClass('checked');
	});
}).on('click', '#account-avatar a[data-file]', function(e) {
	var t = $(this);
	e.preventDefault();
	$.ajax({
		method: 'POST',
		url: window.location.url,
		data: {
			remove_avatar: t.data('file')
		},
		dataType : "json",
		success: function() {
			t.closest('li').remove();
			var img = $('#settings-contents h2 img'), time = new Date().getTime(), data = {
				src: img.attr('src').replace(/\.png?[0-9]+$|\.png$/, '.png?'+time)
			};
			if(img.attr('srcset'))
				data.srcset = img.attr('srcset').replace(/\.png?[0-9]+ 2x$|\.png 2x$/, '.png?'+time+' 2x');
			img.attr(data);
		},
		error: function(xhr) {
			htmlGrowl(xhr.responseJSON.message);
		}
	})
}).on('change', 'label.checkable input[type=checkbox]', function() {
	var t = $(this);
	t.closest('label')[t.is(':checked') ? 'addClass' : 'removeClass']('checked');
}).on('click', '*[data-opener-for]', function(e) {
	e.preventDefault();
	var t = $(this), target = $(t.data('opener-for'));
	if(t.hasClass('open')) {
		target.slideUp(function() {
			t.removeClass('open');
			target.removeClass('open');
		});
	} else {
		t.addClass('open');
		target.slideDown(function() {
			target.addClass('open');
		});
	}
}).on('submit', 'form:has(input[name=password]):has(input[name=confirm_password])', function(e) {
	var t = $(this), confirmed = t.find('[name=confirm_password]');
	if(t.find('[name=password]').val() != confirmed.val()) {
		e.preventDefault();
		confirmed.get(0).setCustomValidity(confirmed.data('mismatch'));
		confirmed.get(0).reportValidity();
	}
}).on('focus', 'label.error input, label.error select', function() {
	$(this).closest('label').addClass('focus');
}).on('blur', 'label.error input, label.error select', function() {
	$(this).closest('label').removeClass('focus');
}).on('change', 'label.error input, label.error select', function() {
	$(this).closest('label').removeClass('error').find('.error_message').remove();
}).on('click', '#careers_tabs_control > li > a', function(e) {
	e.preventDefault();
	if(isMobile()) { // Mobile
		$('html, body').animate({scrollTop: $("#careers_tabs_control").offset().top}, 2000);
		var t = $(this);
		t.closest('ul').find('> li > div').slideUp(function() {
			$(this).remove();
		});
		t.closest('ul').find('> li.active').removeClass('active');
		t.after('<div style="display: none" class="why-feral">'+$('#careers_tabs > div#careers-text-'+t.attr('href').substr(1)).html()+'</div>');
		t.closest('li').addClass('active');
		t.next().slideDown();
	} else { // Desktop
		$('#careers_tabs_control li.active').removeClass('active');
		$(this).parent('li').addClass('active');
		$('#careers_tabs > div:visible').slideUp();
		$('#careers_tabs > div#careers-text-'+$(this).attr('href').substr(1)).slideDown();
	}
}).on('click', '#careers_tabs .careers-close-button', function(e) { // Desktop close button
	e.preventDefault();
	$(this).closest('div').slideUp();
	$('#careers_tabs_control li.active').removeClass('active');
	$('#careers_tabs > div#careers-text-home').slideDown();
	$('html, body').animate({scrollTop: $("#careers_tabs + aside").offset().top}, 2000);
}).on('click', '#careers_tabs_control > li > div .careers-close-button', function(e) { // Mobile close button
	e.preventDefault();
	$(this).closest('div').slideUp(function() {
		$(this).remove();
	});
	$(this).closest('ul').find('> li.active').removeClass('active');
	$('html, body').animate({scrollTop: $("#careers_tabs + aside").offset().top}, 2000);
}).on('click', '#careers-list > ul > li:not(.open) > h1 a', function(e) {
	e.preventDefault();
	var t = $(this), li = t.closest('li'), prev = li.closest('ul').find('> li.open');
	prev.find('> .container-fluid').slideUp({
		complete: function() {
			prev.removeClass('open');
		}
	});
	li.find('> .container-fluid').slideDown({
		complete: function() {
			li.addClass('open');
		}
	});
	window.history.pushState(null, '', t.attr('href'));
}).on('click', '#careers-list a.close-job', function(e) {
	e.preventDefault();
	var t = $(this), li = t.closest('li');
	li.find('> .container-fluid').slideUp({
		complete: function() {
			li.removeClass('open');
		}
	});
	window.history.pushState(null, '', t.attr('href'));
}).on('click', '#careers-list > ul > li.open > h1 a', function(e) {
	e.preventDefault();
	var t = $(this), li = t.closest('li');
	li.find('> .container-fluid').slideUp({
		complete: function() {
			li.removeClass('open');
		}
	});
	window.history.pushState(null, '', t.attr('href'));
}).on('change', '#contactModal select[name=section]', function() {
	window.location.hash = 'contact/'+$(this).val();
}).on('change', '.modal .platforms input[type=radio]', function() {
	var t = $(this).closest('.platforms');
	t.find('label').removeClass('active');
	t.find('input:checked').closest('label').addClass('active');
}).on('change', '#contactModal select[name=game]', function() {
	var t = $(this).find('option:selected'), html = '', platforms = t.data('platforms').split(','), i;
	for(i = 0; i < platforms.length; i++)
		html += '<label class="icon-'+platforms[i].toLowerCase()+'"><input type="radio" name="platform" value="'+platforms[i]+'" checked=""></label> ';
	t.closest('form').find('.platforms').html(html).find('input:first').prop('checked', true).trigger('change');
}).on('change', '#contactModal input[name=platform]', function() {
	var t = $(this), val = t.val();
	t.closest('.modal-body').find('.special')[val == 'MAC' || val == 'LINUX' ? 'show' : 'hide']();
}).on('submit', '#contactModal form', function(e) {
	e.preventDefault();
	var form = $(this), data = new FormData(), i = 0;
	form.find('input[name], textarea[name], select[name]').each(function() {
		var t = $(this), name = t.attr('name');
		if(t.prop('type') == 'file') {
			var fileList = t[0].files;
			if(fileList.length > 0)
				_.each(fileList, function(file) {
					data.append(name, file, file.name);
				});
		} else if(t.prop('type') == 'checkbox' || t.prop('type') == 'radio') {
			if(t.is(':checked'))
				data.append(name, t.val());
		} else if(t.prop('multiple')) {
			if(_.isArray(t.val()))
				_.each(t.val(), function(v) {
					data.append(name, v);
				});
			else
				data.append(name, t.val());
		} else if(!_.isEmpty(t.val()))
			data.append(name, t.val());
	});
	if(form.hasClass('confirming')) {
		form.find('.modal-footer').removeClass('modal-error').find('.error-message').remove();
		form.find('.invalid').removeClass('invalid').find('small').remove();
		form.find('.modal-footer .btn').prop('disabled', true).filter('.btn-success').addClass('loading');
		$.ajax({
			url: window.location.href,
			method: 'POST',
			data: data,
			processData: false,
			contentType: false,
			dataType: 'json',
			success: function(data) {
				form.find('.modal-footer .btn').prop('disabled', false).filter('.btn-success').removeClass('loading');
				form.toggleConfirmation(false).html('<div class="modal-body modal-success"><h3>'+data.header+'</h3><div>'+data.body+'</div><button type="button" class="btn btn-success" data-dismiss="modal">Close</button></div>');
			},
			error: function(xhr) {
				var json = xhr.responseJSON;
				form.toggleConfirmation(false);
				_.each(json, function(error, field) {
					form.find('[name='+field+']').parent().addClass('invalid').append('<small>'+error+'</small>');
				});
				form.find('.modal-header + .modal-body').slideDown();
				form.find('.modal-summary').slideUp();
				form.find('.modal-footer .btn').prop('disabled', false).filter('.btn-success').removeClass('loading');
				if(!_.isUndefined(json.sending_email))
					form.find('.modal-footer').addClass('modal-error').prepend('<p class="error-message">'+json.sending_email+'</p>');
			}
		});
	} else {
		var summary = form.toggleConfirmation(true).find('.modal-summary'), key, html = '';
		summary.find('#contactSummaryEmail').html(_.escape(data.get('email')));
		summary.find('#contactSummaryMessage').html(_.escape(data.get('message')));
		if(data.has('report'))
			html += '<li>'+_.escape(data.get('report').name)+'</li>';
		_.each(data.getAll('files[]'), function(file) {
			html += '<li>'+_.escape(file.name)+'</li>';
		});
		summary.find('#contactSummaryAttachments').html(html);
		form.find('.modal-header + .modal-body').slideUp(400);
		summary.slideDown(400, function() {
			summary.closest('.modal-content').animate({ scrollTop: 0 }, 400);
		});
	}
}).on('click', '#contactModal form .modal-footer .btn-info', function(e) {
	e.preventDefault();
	var form = $(this).closest('form');
	if(form.hasClass('confirming')) {
		form.toggleConfirmation(false);
		form.find('.modal-header + .modal-body').slideDown();
		form.find('.modal-summary').slideUp();
	} else
		form.closest('.modal').modal('hide');
}).on('click', '#checkoutDetails button', function(e) {
	e.preventDefault();
	$.ajax({
		url: '/helpers/change_country_modal.php',
		method: 'GET',
		data: {
			href: window.location.href
		},
		dataType: 'json',
		success: function(data) {
			var modal = $('#changeCountryModal'), html = '<p>'+_.escape(data.labels.notice)+'</p><select name="country">';
			modal.find('#changeCountryModalLabel h5').html(data.labels.header);
			_.each(_.sortBy(data.countries, 'name'), function(country) {
				html += '<option value="'+country.code+'"';
				if(country.change_currency)
					html += ' data-currency="true"';
				if(country.restricted)
					html += ' data-restricted="true"';
				if(country.code == data.current)
					html += ' selected';
				html += '>'+_.escape(country.name)+'</option>';
			});
			modal.find('.modal-body').html(html+'</select><p id="countryChangeCurrency">'+data.labels.change_currency+'</p><p id="countryChangeRestricted">'+data.labels.restricted+'</p>');
			modal.modal('show');
		}
	})
}).on('change', '#changeCountryModal select', function() {
	var t = $(this), selected = t.find('option:selected'), body = t.closest('.modal-body');
	body.find('#countryChangeCurrency')[selected.data('currency') ? 'show' : 'hide']();
	body.find('#countryChangeRestricted')[selected.data('restricted') ? 'show' : 'hide']();
}).on('click', '#checkoutModal button[type=submit][data-method]', function(e) {
	e.preventDefault();
	var button = $(this), form = button.closest('form');
	form.find('input[name=payment_method]').val(button.data('method'));
	form.submit();
}).on('submit', '#buyModal form', function(e) {
	e.preventDefault();
	var form = $(this), action = form.attr('action'), modal = form.closest('.modal'), button = form.find('button[type=submit]').prop('disabled', true).addClass('loading');
	$.ajax({
		url: '/helpers/checkout.php?'+$.param({
			href: window.location.href.replace(window.location.hash, action)
		}),
		method: 'POST',
		data: form.serialize(),
		dataType: 'json',
		success: function(data, status, xhr) {
			button.prop('disabled', false).removeClass('loading');
			var basketModal = $('#checkoutModal');
			buildWholeCheckoutSection(xhr.getResponseHeader('X-Checkout-Section'), data, basketModal);
			var navBasket = $('#primary-nav-basket');
			if(navBasket.length == 0)
				$('#primary-nav-settings').before('<div id="primary-nav-basket" class="nav-expand">'+buildNavBasket(data)+'</div>');
			else
				navBasket.html(buildNavBasket(data));
			basketModal.modal('show');
			modal.modal('hide');
			window.history.pushState(null, '', action);
		},
		error: function(xhr) {
			button.prop('disabled', false).removeClass('loading');
			growl(xhr.responseText);
		}
	});
}).on('submit', '#checkoutModal form', function(e) {
	e.preventDefault();
	var form = $(this), action = form.attr('action'), modal = form.closest('.modal'), button = form.find('button[type=submit]').prop('disabled', true).addClass('loading');
	form.find('.invalid').removeClass('invalid').find('small').remove();
	$.ajax({
		url: '/helpers/checkout.php?'+$.param({
			href: _.isEmpty(action) ? window.location.href : window.location.href.replace(window.location.hash, action)
		}),
		method: 'POST',
		data: form.serialize(),
		dataType: 'json',
		success: function(data, status, xhr) {
			button.prop('disabled', false).removeClass('loading');
			var section = xhr.getResponseHeader('X-Checkout-Section');
			if(section == 'basket') {
				modal.find('.modal-body > .row').html(buildBasketSection(data));
				modal.find('.basket-total').html('<small>'+data.labels.total+':</small> '+data.total);
				$('#primary-nav-basket').html(buildNavBasket(data));
			} else if(section == 'payment') {
				window.history.pushState(null, '', action);
				buildWholeCheckoutSection(section, data, modal);
			}
		},
		error: function(xhr) { // FIXME: fix basket building
			button.prop('disabled', false).removeClass('loading');
			if(xhr.status == 404) {
				$('#primary-nav').removeClass('nav-expanded').removeClass('nav-expand-basket');
				$('#primary-nav-basket').remove();
				modal.modal('hide');
			} else {
				var section = xhr.getResponseHeader('X-Checkout-Section');
				if(section == 'payment') {
					_.each(xhr.responseJSON, function(message, field) {
						var input = form.find('input[name="'+field+'"]');
						input.parent().addClass('invalid').append('<span>'+message+'</span>');
					});
				} else
					growl(xhr.responseText)
			}
		}
	});
}).on('submit', '#primary-nav-basket li form', function(e) {
	e.preventDefault();
	var form = $(this);
	$.ajax({
		url: '/helpers/checkout.php?'+$.param({
			href: _.isEmpty(window.location.hash) ? window.location.href+'#checkout/basket' : window.location.href.replace(window.location.hash, '#checkout/basket'),
			skip_upgrades: true
		}),
		method: 'POST',
		data: form.serialize(),
		dataType: 'json',
		success: function(data) {
			$('#primary-nav-basket').html(buildNavBasket(data))
		},
		error: function(xhr) {
			if(xhr.status == 404) {
				$('#primary-nav').removeClass('nav-expanded').removeClass('nav-expand-basket');
				$('#primary-nav-basket').remove();
			} else
				growl(xhr.responseText);
		}
	})
}).on('submit', '.video-age-gate', function(e) {
	e.preventDefault();
	var form = $(this), data = {}, yearsAgo = new Date();
	yearsAgo.setFullYear(yearsAgo.getFullYear()-18);
	_.each(form.serializeArray(), function(field) {
		data[field.name] = field.value;
	});
	var value = data.year+'-'+data.month+'-'+data.day;
	setCookie(data.name, value, 30);
	if(yearsAgo.toISOString().substr(0, 10) < value) {
		$('.video-age-gate').each(function() {
			var t = $(this);
			t.before('<p class="access-restricted">'+data.error+'</p>');
			t.next().remove();
			t.remove();
		});
	} else
		$('.video-age-gate').remove();
});